<template>
  <v-card class="pa-8">
    <ComponentTitleWithDataLoader
      :title="this.$t('employer.title')"
      class="mb-4"
    ></ComponentTitleWithDataLoader>
    <v-container v-if="employer" v-show="!dataIsLoading" data-cy="employerData">
      <v-row>
        <v-col>
          <v-text-field
            v-model="employer.name"
            dense
            disabled
            :label="$t('employer.name')"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model="employer.address.address1"
            dense
            disabled
            :label="$t('global.address.address1')"
          />
        </v-col>
      </v-row>
      <v-row v-if="employer.address.address2">
        <v-col>
          <v-text-field
            v-model="employer.address.address2"
            dense
            disabled
            :label="$t('global.address.address2')"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model="employer.address.zipCode"
            dense
            disabled
            :label="$t('global.address.zipCode')"
          />
        </v-col>
        <v-col>
          <v-text-field
            v-model="employer.address.city"
            dense
            disabled
            :label="$t('global.address.city')"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="employer.website">
          <v-text-field
            v-model="employer.website"
            dense
            disabled
            :label="$t('employer.website')"
          />
        </v-col>
        <v-col>
          <v-text-field
            v-if="employer.workingField"
            v-model="employer.workingField.name"
            dense
            disabled
            :label="$t('employer.working-field')"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-checkbox
            v-model="employer.hasSocialSubvention"
            :label="$t('employer.has-social-subvention')"
            data-cy="employer.hasSocialSubvention"
            readonly
          />
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex'
import ComponentTitleWithDataLoader from '@/components/layout/ComponentTitleWithDataLoader.vue'

export default {
  components: {
    ComponentTitleWithDataLoader
  },
  props: {
    employer: {
      type: Object,
      default: null
    }
  },
  computed: {
    ...mapGetters('page', ['dataIsLoading'])
  }
}
</script>
