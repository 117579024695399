<template>
  <div>
    <PageTitle
      :title="employer ? employer.name : $t('employer.title')"
      icon="mdi-domain"
      back-button
    ></PageTitle>
    <v-row>
      <v-col cols="7">
        <employer-data :employer="employer" />
      </v-col>
      <v-col cols="5">
        <offers-list-simplified
          :offers="employerOffers"
          data-cy="employerOffers"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import {mapActions, mapState} from 'vuex'
import PageTitle from '@/components/layout/PageTitle.vue'
import OffersListSimplified from '@/components/offers/OffersListSimplified.vue'
import EmployerData from '../components/employers/EmployerData.vue'

export default {
  components: {
    PageTitle,
    EmployerData,
    OffersListSimplified
  },
  computed: {
    ...mapState('employers', ['employer']),
    ...mapState('jobOffers', ['jobOffers']),
    employerOffers() {
      return this.jobOffers.items
    },
    employerId() {
      return this.$route.params.id
    }
  },
  watch: {
    '$route.params.id': {
      handler(employerId) {
        if (employerId) {
          this.getEmployer(employerId)
          this.getJobOffers({
            'employer_representative__employer_id': employerId,
            'page': '-1' // All non paginated
          })
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    ...mapActions('jobOffers', ['getJobOffers']),
    ...mapActions('employers', ['getEmployer']),
    saved(employerId) {
      const newPath = `/employers/${employerId}/details`
      if (this.$router.history.current.path !== newPath) {
        this.$router.replace(newPath)
      }
    }
  }
}
</script>
