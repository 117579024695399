<template>
  <div class="d-flex mb-3">
    <data-loader class="mr-4"></data-loader>
    <h3 v-if="!dataIsLoading">
      {{ title }}
    </h3>
    <h3 v-else>{{ $t('global.loading-data') }}</h3>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DataLoader from '@/components/common/DataLoader.vue'

export default {
  components: { DataLoader },
  props: {
    title: { type: String, required: false, default: '' }
  },
  computed: {
    ...mapGetters('page', ['dataIsLoading'])
  }
}
</script>

<style scoped></style>
